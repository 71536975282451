import { Title } from '@solidjs/meta';
import { Button, Container, Heading, HorizontalRule, Link, Page, Picture, Section, TextLink } from '@troon/ui';
import { For, Show } from 'solid-js';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { PictureSection } from '../../components/layouts/picture-section';
import { Grid, GridFive, GridQuarter, GridSeven, GridThreeQuarter } from '../../components/layouts/grid';
import type { RouteDefinition } from '@solidjs/router';

export default function GiftCards() {
	return (
		<>
			<Title>Gift Cards | Troon</Title>
			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/management-services.jpg`}>
				<Heading as="h1">Troon Gift Cards</Heading>
			</Hero>

			<Container>
				<Page>
					<PictureSection src={`${getConfigValue('IMAGE_HOST')}/digital/photo/troon-gift-card.jpg`} crop="center">
						<Heading as="h2">Looking to dazzle that special golfer in your life?</Heading>
						<p>
							Give the gift of golf with Troon’s gift card. Available in any denomination, the Troon Gift Card is
							flexible and offers a great value at the top clubs and courses around! Give the gift of Troon Today!
						</p>
						<div class="flex gap-4">
							<Button as={Link} href="https://troon.cardfront.net/" class="size-fit grow-0">
								Purchase today
							</Button>
							<Button as={Link} appearance="secondary" href="https://www.getmybalance.com/" class="size-fit grow-0">
								Check your balance
							</Button>
						</div>
					</PictureSection>
					<HorizontalRule />
					<Section>
						<Heading as="h2" class="text-center">
							Locations to Use Your Gift Card
						</Heading>
						<ul class="list-disc gap-16 ps-6 sm:columns-2 md:gap-24 lg:columns-3">
							<For each={courses}>
								{(state) => (
									<li class="mb-4 break-inside-avoid-column">
										<strong>{state.location}</strong>
										<ul class="flex list-disc flex-col gap-1 ps-6">
											<For each={state.facilities}>
												{(facility) => (
													<li>
														{facility.name}
														<Show when={facility.inPerson}>
															<sup>*</sup>
														</Show>
													</li>
												)}
											</For>
										</ul>
									</li>
								)}
							</For>
						</ul>
						<p class="text-sm italic">* In-person purchase location</p>
					</Section>

					<HorizontalRule />

					<Grid>
						<GridSeven>
							<Section>
								<Heading as="h2">Gift Card Terms and Usage</Heading>
								<p>
									Troon Gift Cards may be redeemed toward merchandise and golf fees. Gift Cards may be redeemed for food
									and beverage at select facilities. Troon Gift Cards may be redeemed at the noted participating
									Troon-managed facilities. Troon Gift Cards are not redeemable for cash and will not be replaced if
									lost, stolen or used without authorization.{' '}
									<b>
										Except as prohibited by law, after 12 months from issue date, a $5 per month maintenance fee will be
										charged and applied to any remaining balance on Troon Gift Cards.
									</b>
								</p>
								<p>Card is issued by Pathward, N.A., Member FDIC.</p>
							</Section>
						</GridSeven>
						<GridFive>
							<Section class="rounded bg-neutral-100 p-8">
								<p>
									Call <TextLink href="tel:18007558713">1.800.755.8713</TextLink> for Customer Service. Card is issued
									by Pathward, N.A., Member FDIC. After Card Plastic Valid Thru date, contact us for a no cost
									replacement Card. $5 fee to replace lost, stolen or damaged Card, except as prohibited by law.
								</p>
							</Section>
						</GridFive>
					</Grid>

					<HorizontalRule />

					<Grid>
						<GridQuarter>
							<Picture
								src={`${getConfigValue('IMAGE_HOST')}/digital/photo/troon-gift-card-old.png`}
								sizes={[[250, 157]]}
								alt="Old Troon Gift Card"
							/>
						</GridQuarter>
						<GridThreeQuarter>
							<Section>
								<Heading as="h3" size="h4">
									Does your gift card look like this?
								</Heading>

								<p>These gift cards are no longer sold, but are still redeemable at the following locations:</p>
								<Heading as="h4" size="h5">
									Arizona
								</Heading>
								<ul class="flex list-disc flex-col gap-1 ps-6">
									<li>Ak-Chin Southern Dunes Golf Club</li>
									<li>Copper Canyon Golf Club</li>
									<li>Golf Club of Estrella</li>
									<li>Lookout Mountain Golf Club</li>
									<li>Poston Butte Golf Club</li>
									<li>The Phoenician</li>
									<li>Troon North Golf Club</li>
								</ul>
								<Heading as="h4" size="h5">
									Georgia
								</Heading>
								<ul class="flex list-disc flex-col gap-1 ps-6">
									<li>The Westin Savannah Harbor Golf Resort & Spa</li>
								</ul>
							</Section>
						</GridThreeQuarter>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const courses: Array<{ location: string; facilities: Array<{ name: string; inPerson?: true }> }> = [
	{
		location: 'Alabama',
		facilities: [
			{ name: 'Gateway Park Golf Course' },
			{ name: 'Highland Park Golf Course' },
			{ name: 'Lagoon Park Golf Course' },
			{ name: 'NorthRiver Yacht Club', inPerson: true },
		],
	},
	{
		location: 'Arizona',
		facilities: [
			{ name: 'Ak-Chin Southern Dunes Golf Club', inPerson: true },
			{ name: 'Antelope Hills Golf Course' },
			{ name: 'Arizona National Golf Club' },
			{ name: 'Blackstone Country Club at Vistancia' },
			{ name: 'Copper Canyon Golf Club', inPerson: true },
			{ name: 'Golf Club of Estrella' },
			{ name: 'Lookout Mountain Golf Club' },
			{ name: 'Poston Butte Golf Club', inPerson: true },
			{ name: 'Sterling Grove Golf + Country Club' },
			{ name: 'The Phoenician' },
			{ name: 'Troon North Golf Club' },
			{ name: 'Wickenburg Ranch Golf Club' },
		],
	},
	{ location: 'Arkansas', facilities: [{ name: 'Mystic Creek Golf Club' }] },
	{
		location: 'California',
		facilities: [
			{ name: 'California Country Club' },
			{ name: 'Classic Club' },
			{ name: 'El Macero Country Club' },
			{ name: 'Hiddenbrooke Golf Club' },
			{ name: 'Maderas Golf Club' },
			{ name: 'Shadow Hills Golf Club' },
			{ name: 'Silver Creek Valley Country Club' },
			{ name: 'Silverado Resort and Spa' },
			{ name: 'Willowick Municipal Golf Course' },
		],
	},
	{ location: 'Colorado', facilities: [{ name: 'Bookcliff Country Club' }, { name: 'The Ridge' }] },
	{ location: 'Delaware', facilities: [{ name: 'Bayside Resort Golf Club' }, { name: 'Bear Trap Dunes Golf Club' }] },
	{
		location: 'Florida',
		facilities: [
			{ name: 'Eagle Landing Golf Club' },
			{ name: 'King & Bear at World Golf Village Resort' },
			{ name: 'Slammer & Squire at World Golf Village Resort' },
		],
	},
	{ location: 'Georgia', facilities: [{ name: 'The Westin Savannah Harbor Golf Resort & Spa', inPerson: true }] },
	{
		location: 'Hawaii',
		facilities: [
			{ name: 'Ka’anapali Golf Course' },
			{ name: 'Mauna Lani Resort' },
			{ name: 'Ocean Course at Hokuala' },
			{ name: 'Pearl Country Club' },
			{ name: 'The King Kamehameha Golf Club' },
			{ name: 'Volcano Golf and Country Club' },
		],
	},
	{ location: 'Illinois', facilities: [{ name: 'Atkins Golf Club at the University of Illinois' }] },
	{ location: 'Kansas', facilities: [{ name: 'Ironhorse Golf Club' }] },
	{ location: 'Louisiana', facilities: [{ name: 'Calvert Crossing Golf Club' }] },
	{ location: 'Missouri', facilities: [{ name: 'The Lodge of Four Seasons' }] },
	{ location: 'New Jersey', facilities: [{ name: 'Seaview Hotel and Golf Club' }] },
	{ location: 'New York', facilities: [{ name: 'The Golf Club at Mansion Ridge' }, { name: 'The Monster Golf Club' }] },
	{ location: 'North Carolina', facilities: [{ name: 'Maggie Valley Club & Resort' }] },
	{
		location: 'Oklahoma',
		facilities: [
			{ name: 'Mohawk Park Golf Course' },
			{ name: 'Page Belcher Golf Course' },
			{ name: 'The Club at Forest Ridge' },
		],
	},
	{ location: 'Oregon', facilities: [{ name: 'Pronghorn' }] },
	{
		location: 'Texas',
		facilities: [
			{ name: 'Cimarron Hills Golf & Country Club' },
			{ name: 'Kissing Tree Golf Club' },
			{ name: 'La Cantera Resort & Spa' },
			{ name: 'The Rawls Course Resort & Spa' },
		],
	},
	{
		location: 'Utah',
		facilities: [{ name: 'Alpine Country Club (UT)' }, { name: 'The Inn at Entrada' }],
	},
	{ location: 'Washington', facilities: [{ name: 'Tri-Mountain Golf Course' }] },
	{ location: 'Wyoming', facilities: [{ name: 'The Powder Horn Golf Club' }] },
];
